.Filmfare-Award {
  background-image: url(./images/Filmfare-Award.png);
  background-position: right top;
  padding-right: 80px !important;
  background-size: 36px auto;
  background-repeat: no-repeat;
}

.multi-talented-s-card h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0px;
  padding: 0px;
}

.multi-talented-s-card p {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
}
