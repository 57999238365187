@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glass {
  width: 35px;
  height: 80px;
  position: relative;
}
.glass:after {
  content: "";
  position: absolute;
  inset: 0;
  padding: 3px 5px;
  border-bottom: 4px solid #a7c7cb;
  background: linear-gradient(#fca43c 0 0) bottom no-repeat content-box, #dbe1e3;
  mix-blend-mode: darken;
  animation: sip 2.5s infinite linear;
}
.glass:before {
  content: "";
  position: absolute;
  inset: -18px calc(50% - 2px) 8px;
  background: #eb6b3e;
  transform-origin: bottom;
  transform: rotate(8deg);
}
@keyframes sip {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 100% 5%;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/*loading*/

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--loading-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.dot-1,
.dot-2,
.dot-3,
.dot-4,
.dot-5 {
  position: relative;
  content: "";
  height: var(--dot-height);
  width: var(--dot-width);
  border-radius: 50%;
  filter: drop-shadow(0px 2px 2px rgba(109, 6, 6, 0.521))
    drop-shadow(0px 3px 0.1px rgba(0, 0, 0, 0.3));

  animation: jumping-dots 2s cubic-bezier(0.55, 1.55, 0.55, 1.55) infinite;

  transition: var(--timing);
}

.dot-1::before,
.dot-2::before,
.dot-3::before,
.dot-4::before,
.dot-5::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 50%;
  font-weight: 800;
  font-size: var(--font-size-loading);
  font-family: var(--font-family-loading);
  color: var(--font-color-loading);
  animation: jumping-dots 2s cubic-bezier(0.55, 1.55, 0.55, 1.55) infinite;
}

.dot-1::before {
  content: "A";
}

.dot-2::before {
  content: "N";
  animation-delay: 0.2s;
}

.dot-3::before {
  content: "U";
  animation-delay: 0.4s;
}

.dot-4::before {
  content: "J";
  animation-delay: 0.6s;
}
.dot-5::before {
  content: "...";
  animation-delay: 0.8s;
}

.dot-1 {
  background-color: var(--dot-1-color);
}

.dot-2 {
  background-color: var(--dot-2-color);
  animation-delay: 0.2s;
}

.dot-3 {
  background-color: var(--dot-3-color);
  animation-delay: 0.4s;
}

.dot-4 {
  background-color: var(--dot-4-color);
  animation-delay: 0.6s;
}

.dot-4 {
  background-color: var(--dot-5-color);
  animation-delay: 0.8s;
}

@keyframes jumping-dots {
  0%,
  25% {
    transform: translateY(var(--jump-height));
    color: transparent;
  }
  50%,
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

body {
  font-family: "Montserrat", sans-serif;
}
* {
  box-sizing: border-box;
}
h1 {
}

.main-section-wrap {
  margin-top: 100px;
}

.home-sec1-bg {
  padding: auto;
}
.home-sec1-wrap {
  display: flex;
  width: 100%;
  position: relative;
}
.home-sec1-1 {
  flex: 1;
  display: flex;
  padding-right: 5%;
}
.home-sec1-2 {
  width: 460px;
  position: relative;
}
.home-sec1-2 img {
  width: 90%;
  height: auto;
  position: relative;
}
.home-sec1-2:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 80%;
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 0px 0px 32px 7px #fff;
  z-index: -1;
  bottom: 0px;
  left: 5%;
}
.home-text-1 {
  margin: auto;
}
.home-text-1 a {
  text-decoration: none;
  color: inherit;
}
.home-text-1 a:hover {
  text-decoration: underline;
}
.home-text-1 h1 {
  color: #783a43;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  padding: 0px;
}
.home-text-1 h2 {
  color: #000;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
  padding: 14px 0px;
}

h2 {
  color: #783a43;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 20px 0px;
}

.social-media-wrap {
  position: absolute;
  bottom: 30px;
  left: 0px;
}

.social-media-wrap-2 {
  position: relative !important;
  bottom: auto !important;
  left: 0px;
  margin-top: 30px !important;
}

.social-media-wrap-1 {
  position: relative !important;
  bottom: 30px;
  margin: 0 auto;
  display: contents;
}
.multi-talented-section h1 {
  color: #783a43;
}

.font-style-1 i {
  font-weight: 600;
  font-style: italic;
  color: #783a43;
}
.social-media-wrap ul {
  margin: 0px;
  padding: 0px;
  display: flex;
}
.social-media-wrap-1 ul {
  width: fit-content !important;
  margin: 0 auto;
}

.social-media-wrap ul li {
  margin: 0px 10px;
}
.social-media-wrap ul li svg {
  margin: 18px 0px 0px 0px;
}
.social-media-wrap ul li a {
  display: flex;
  /* background: linear-gradient(145deg,#1e2024,#23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e; */
  border-radius: 6px;

  display: inline-block;
  font-size: 14px;
  height: 60px;
  letter-spacing: 2px;
  line-height: 60px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  width: 60px;
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
}
.social-media-wrap ul li a:hover {
  transform: translateY(-3px);
  background: #783a43;
}
.social-media-wrap ul li a svg {
  color: #000;
}

.social-media-wrap ul li a:hover svg {
  color: #fff;
}
.social-media-wrap ul li a:hover:before {
  opacity: 1;
}
.social-media-wrap ul li a:before {
  background: #783a43;
}

.multi-talented-s-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.multi-talented-s-card {
  width: 30%;
  border-radius: 5px;
  background: linear-gradient(280deg, #fff -4.22%, #e3e9ec 84.62%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  padding: 20px 20px 60px 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
  transition: transform 0.2s;
  position: relative;
}
.multi-talented-s-card a {
  text-decoration: none;
  color: inherit;
}
.multi-talented-s-card:hover {
  transform: scale(1.02) !important;
}

.multi-talented-s-card img {
  width: 100%;
}
.multi-talented-s-wrap h3 {
  color: #000;

  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hg-1-wrap {
  display: flex;
  width: 100%;
}
.hg-1-1 {
  width: 40%;
}
.img-60 {
  height: 56%;

  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.img-40 {
  height: 44%;

  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.hg-1-2 {
  width: 60%;
}

.footer {
  text-align: center;
}

.footer p {
  color: #646669;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.line-1 {
  height: 1px;
  background-color: #c2c2c2;
  margin: 20px 0px;
}
.carousel-img {
  text-align: center;
  margin: 0 auto;
}
.carousel-img img {
  width: 90%;
  height: auto;
}
.slick-slide img {
  display: inline-block !important;
}
.slick-prev {
  left: -40px !important;
}
.slick-next:before {
  content: url('data:image/svg+xml,<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_2_57)"><rect x="33.2162" y="33.9189" width="30.2162" height="30.9189" rx="15.1081" transform="rotate(-180 33.2162 33.9189)" fill="url(%23paint0_linear_2_57)"/></g><g clip-path="url(%23clip0_2_57)"><path d="M16.1594 13.6194C16.0767 13.6194 15.9922 13.652 15.9288 13.7155C15.802 13.8423 15.802 14.0498 15.9288 14.1766L20.2693 18.5172L15.9922 22.7943C15.8654 22.9211 15.8654 23.1287 15.9922 23.2555C16.119 23.3823 16.3265 23.3823 16.4533 23.2555L20.963 18.7477C21.0898 18.6209 21.0898 18.4134 20.963 18.2866L16.3919 13.7155C16.3265 13.6501 16.2439 13.6194 16.1594 13.6194Z" fill="%23FF014F"/></g><defs><filter id="filter0_d_2_57" x="0" y="0" width="40.2162" height="40.9189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="2" dy="2"/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.890196 0 0 0 0 0.92549 0 0 0 1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_57"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_57" result="shape"/></filter><linearGradient id="paint0_linear_2_57" x1="64.9432" y1="64.8379" x2="34.1488" y2="59.2863" gradientUnits="userSpaceOnUse"><stop stop-color="white"/><stop offset="1" stop-color="%23E3E9EC"/></linearGradient><clipPath id="clip0_2_57"><rect width="9.83784" height="9.83784" fill="white" transform="matrix(0 1 -1 0 23.3783 13.5405)"/></clipPath></defs></svg>') !important;
  width: 20px !important;
}

.slick-prev:before {
  width: 20px !important;

  content: url('data:image/svg+xml,<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_2_55)"><rect x="3.78381" y="3" width="30.2162" height="30.9189" rx="15.1081" fill="url(%23paint0_linear_2_55)"/></g><g clip-path="url(%23clip0_2_55)"><path d="M20.8406 23.2996C20.9233 23.2996 21.0078 23.2669 21.0712 23.2035C21.198 23.0767 21.198 22.8692 21.0712 22.7423L16.7307 18.4018L21.0078 14.1246C21.1346 13.9978 21.1346 13.7903 21.0078 13.6635C20.881 13.5367 20.6735 13.5367 20.5467 13.6635L16.037 18.1712C15.9102 18.298 15.9102 18.5055 16.037 18.6324L20.6081 23.2035C20.6735 23.2688 20.7561 23.2996 20.8406 23.2996Z" fill="%23FF014F"/></g><defs><filter id="filter0_d_2_55" x="0.783813" y="0" width="40.2162" height="40.9189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="2" dy="2"/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.890196 0 0 0 0 0.92549 0 0 0 1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_55"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_55" result="shape"/></filter><linearGradient id="paint0_linear_2_55" x1="35.5108" y1="33.9189" x2="4.71644" y2="28.3674" gradientUnits="userSpaceOnUse"><stop stop-color="white"/><stop offset="1" stop-color="%23E3E9EC"/></linearGradient><clipPath id="clip0_2_55"><rect width="9.83784" height="9.83784" fill="white" transform="matrix(0 -1 1 0 13.6217 23.3784)"/></clipPath></defs></svg>') !important;
}

.footer {
  text-align: center;
  padding: 10px 0px 20px 0px;
}
.get-in-touch {
  text-align: center;
  padding: 30px 0px;
}

.homepage-gallery {
  padding: 20px 0px;
}

.get-in-touch h3 {
  color: #3c3e41;
  margin: 0px 0px 30px 0px;
  padding: 0px;

  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.awards-gallery img {
  width: 100%;
  height: auto;
}

.top-gap-1 {
  margin: 0px;
  padding: 10px 0px 50px 0px;
}
p {
  color: #646669;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.button-1 {
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  padding: 10px 10px;
  color: #783a43;
  text-decoration: none;
  font-weight: 500;
  margin: 10px 0px 10px 0px;
  display: inline-block;
}
.styles-1 h1 {
  color: #783a43;
}
.styles-1 h2 {
  color: #783a43;
  margin: 30px 0px 20px 0px;
  font-size: 30px;
  font-weight: 500;
}
.image-box-bg h2 {
  color: #000;
}

.image-box-bg {
  border-radius: 10px;
  background: linear-gradient(280deg, #fff -4.22%, #e3e9ec 84.62%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  margin-top: 40px;
}
.image-box-bg h2 {
  font-size: 20px;
  padding: 20px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}

.image-box-bg-3 {
  border-radius: 5px;
  background: linear-gradient(280deg, #fff -4.22%, #e3e9ec 84.62%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  margin-top: 40px;
  border: 1px solid #e2e2e2;
}
.image-box-bg-3 h4 {
  font-size: 20px;
  padding: 20px 20px 10px 20px;
  font-weight: 500;
  margin: 0px;
  border-bottom: 1px solid #ccc;
  color: #000;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.image-box-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 96%;
  margin: 20px auto 0 auto;
}
.image-box-1 {
  width: 30%;
  padding: 0px;
  margin-bottom: 30px;
}

.image-box-1 img {
  width: 100%;
  height: auto;
  padding: 0px;
}
.image-box-1 iframe {
  width: 100%;
  height: auto;
  padding: 0px;
  border-radius: 10px;
  min-height: 220px;
  background: #000;
}

.padding-1 {
  padding: 20px;
}
.padding-2 {
  padding: 0px 20px 20px 20px;
}
table.rtr-table {
  margin-bottom: 20pxx;
}
table.rtr-table thead {
  top: 80px !important;
}
.communications-coach-wrap {
  display: flex;
  width: 100%;
}
.communications-coach-1 {
  border-radius: 5px;
  width: 60%;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
}
.communications-coach-1-1 {
  border-radius: 5px;
  background: linear-gradient(280deg, #fff -4.22%, #e3e9ec 84.62%);
  box-shadow: 0px 4px 4px 0px #e3e9ec;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.communications-coach-2 {
  flex: 1;
}
.communications-coach-2 img {
  width: 100%;
  height: auto;
}
.communications-box {
  /* margin: 0px 0px 20px 0px; */
  margin: auto;
}
.communications-box b {
  display: block;
  margin: 0px 0px 10px 0px;
  font-size: 14px;
  font-weight: 400;
}
.communications-box input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 10px;
  box-sizing: border-box;
}
.communications-box button {
  /* border: 1px solid #ccc; */
  padding: 14px 25px;
  box-sizing: border-box;
  /* border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec; */
  border: 0px;
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  color: #783a43;
}

.media-talented-s-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.media-talented-s-card {
  width: 30%;
  margin: 20px 1.5%;
  border-radius: 5px;
  background: linear-gradient(280deg, #fff -4.22%, #e3e9ec 84.62%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  padding: 20px;
}

.media-talented-s-card img {
  width: 100%;
}
.media-talented-s-wrap h3 {
  color: #000;

  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.media-talented-s-card button {
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
  color: #783a43;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.media-talented-s-card button a {
  text-decoration: none;
  color: #783a43;
  display: block;
}
.table-section table {
  width: 100%;
}
.table-section table td {
  padding: 5px 5px;
  border: 1px solid #ccc;
}

.FooterLogo {
  height: 120px;
  width: auto;
}

.voice-artist-wrap h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #783a43;
}
.bullet-2 ul {
  margin: 0px 0px 0px 24px;
  padding: 0px;
  list-style: disc;
}
.bullet-2 ul li {
  margin: 0px 0px 15px 0px;
  padding: 0px;
  list-style: disc;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.voice-artist-wrap h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
  line-height: 26px;
}
.voice-artist-wrap h4 b {
  color: #646669 !important;
  font-weight: 600;
}

.socialmedia-sec-wrap {
  display: flex;
  width: 100%;
  margin: 25px 0px;
  justify-content: space-between;
}
.socialmedia-sec-wrap iframe {
  border-radius: 10px;
}
.socialmedia-sec-1 {
  border-radius: 5px;
  width: 46%;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 10px;
}
.socialmedia-sec-2 {
  width: 46%;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.table-style-1 table {
  border-collapse: collapse;
}
.table-style-1 table tr td {
  border: 0px;
  padding: 10px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.table-style-1 table thead {
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
}
.table-style-1 table thead th {
  color: #783a43;
  text-align: left;
}
.table-style-1 table tr:first-child th {
  padding: 10px 10px;
}
.table-style-1 {
  overflow-x: auto;
}

.table-style-2 table {
  border-collapse: collapse;
}
.table-style-2 table tr td {
  border: 0px;
  padding: 10px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.table-style-2 table thead {
  border-radius: 5px;
  background: linear-gradient(276deg, #fff -4.5%, #e4eaed 142.88%);
  box-shadow: 2px 2px 5px 0px #dde3ec;
}
.table-style-2 table thead th {
  color: #783a43;
  text-align: left;
}
.table-style-2 table tr:first-child th {
  padding: 10px 10px;
}
.table-style-2 {
  overflow-x: auto;
}
/* .table-style-1 table tr th:last-child {
  text-align: center;
}
.table-style-1 table tr td:last-child {
  text-align: center;
} */

.dd {
  display: block !important;
}
.md {
  display: none !important;
}
.style-2 {
  color: #3a1334 !important;
  font-weight: bold !important;
  white-space: nowrap;
}
@media screen and (max-width: 830px) {
  .dd {
    display: none !important;
  }
  .md {
    display: block !important;
  }
  .home-sec1-2:before {
    background: transparent !important;
    box-shadow: none !important;
  }

  .multi-talented-s-card {
    width: 48%;
  }
  .home-sec1-wrap {
    flex-wrap: wrap;
  }
  .social-media-wrap {
    position: relative;
    bottom: auto;
    left: inherit;
    margin-top: 20px;
  }
  .home-sec1-2 {
    order: 1;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }
  .home-sec1-1 {
    order: 2;
    width: 100%;
  }
  .social-media-wrap {
    order: 3;
  }
  .home-text-1 h1 {
    font-size: 38px;
  }
  .home-text-1 h2 {
    font-size: 30px;
  }
  /* .home-sec1-2 img {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
  } */

  .home-sec1-2 img {
    width: auto;
    margin: 0 auto;
    display: inline-block;
    height: 60vh;
    max-width: 90%;
  }

  .home-sec1-1 {
    padding-right: 0%;
  }
  h2 {
    font-size: 30px;
    margin: 0px 0px 20px 0px;
  }
  .get-in-touch h3 {
    font-size: 20px;
  }

  .social-media-wrap ul li a {
    height: 45px;

    line-height: 45px;

    width: 45px;
  }
  .social-media-wrap ul li svg {
    margin: 10px 0px 0px 0px;
  }
  .image-box-1 {
    width: 47%;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  .communications-coach-wrap {
    flex-wrap: wrap;
  }
  .communications-coach-1 {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    order: 2;
  }

  .communications-coach-2 {
    flex: inherit;
    order: 1;
  }
  .communications-coach-2 {
    width: 100%;
  }

  .top-gap-1 {
    margin: 0px;
    padding: 10px 0px 20px 0px;
  }
  .footer {
    padding: 10px 0px 20px 0px;
  }
  .footer img {
    max-width: 100px;
    height: auto;
  }
  .media-talented-s-wrap h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .media-talented-s-card {
    width: 45%;
    box-sizing: border-box;
  }
  .media-talented-s-wrap {
    justify-content: space-between;
  }
  .socialmedia-sec-wrap {
    flex-wrap: wrap;
    width: 100%;
  }
  .socialmedia-sec-1 {
    width: 100%;
    flex: inherit;
    margin-bottom: 20px;
  }
  .socialmedia-sec-2 {
    width: 100%;
    flex: inherit;
  }
  .slick-next {
    right: 15px !important;
    z-index: 999999;
  }
  .slick-prev {
    left: -10px !important;
    z-index: 999999;
  }
  .hg-1-1 {
    width: 50%;
    padding: 10px 5px;
  }
  .hg-1-2 {
    width: 50%;
    padding: 10px 5px;
  }
}
.gal-img-class-1 {
  width: 100%;
  height: auto;
}
.gal-img-class-2 {
  width: 100%;
  height: auto;
  margin-top: 7vh;
}
.readmore-1 {
  padding: 12px 0px 0px 0px;
  font-size: 14px;
  font-weight: 600;
  display: block;
  cursor: pointer;
  color: #783a43 !important;
  position: absolute;
  bottom: 20px;
}
.readmore-1:hover {
  color: #000 !important;
}
@media screen and (max-width: 600px) {
  .social-media-wrap ul li a svg {
    height: 10px;
    width: auto;
  }
  .social-media-wrap ul li a {
    height: 30px;
    line-height: 30px;
    width: 30px;
  }
  .social-media-wrap ul li {
    margin: 0px 6px;
  }

  .header-new-logo {
    width: 80px;
    margin: auto;
  }
  .media-talented-s-card {
    width: 100%;
    box-sizing: border-box;
  }
  .multi-talented-s-card {
    width: 48%;
    padding: 6px 6px 26px 6px;
    margin-bottom: 20px;
  }
  .readmore-1 {
    padding: 12px 0px 0px 0px;
    font-size: 12px;

    bottom: 12px;
  }

  .multi-talented-s-card p {
    display: none;
  }
  .multi-talented-s-card h3 {
    font-size: 14px !important;
    margin: 10px 0px;
  }
  .home-text-1 h1 {
    font-size: 28px;
  }
  .home-text-1 h2 {
    color: #000;
    font-size: 20px;
  }
  h2 {
    font-size: 20px;
    margin: 0px 0px 20px 0px;
  }
  .get-in-touch h3 {
    font-size: 18px;
  }
  .wrapper {
    width: 92% !important;
    margin: 0 auto;
  }
  .image-box-1 {
    width: 48%;
    margin-bottom: 7px;
  }

  .image-box-1 iframe {
    width: 100%;
    min-height: 60px;
    height: auto;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .image-box-1 iframe {
    height: 100px;
  }

  .table-style-2 table thead th {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }

  .table-style-2 table tr td {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  .table-style-1 table thead th {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }

  .table-style-1 table tr td {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
}
